<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Login Attempts</h1>
        <v-form @submit.prevent="getAttempts">
          <v-row class="pt-8">
            <v-col>
              <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="350px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label class="pl-8">Date</label>
                  <v-text-field
                      v-model="date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dateProto"
                    range>
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col class="pt-10">
              <v-btn small type="submit">Filter</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th style="width:25%">Email</th>
              <th style="width:25%">Date/Time</th>
              <th style="width:25%">Password Successful</th>
              <th style="width:25%">Two Factor Successful</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(attempt, index) in loginAttempts" :key="index">
              <td>{{ attempt.email }}</td>
              <td>{{ attempt.created | prettyDateTime }}</td>
              <td>
                <span v-if="attempt.password_successful">
                  Yes
                </span>
                <span v-else>
                  No
                </span>
              </td>
              <td>
                <span v-if="attempt.two_factor_successful">
                  Yes
                </span>
                <span v-else>
                  No
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-if="page < length">
      <v-col class="text-right pb-10">
        <v-btn @click="page += 1" large>Load More</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'LoginAttempts',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      date: [],
      dateProto: [],
      dateDialogue: false,
      loginAttempts: [],
      page: 0,
      length: 0,
    };
  },
  watch: {
    page() {
      this.getAttempts();
    },
    dateProto () {
      this.date = this.formatDate(this.dateProto);
    },
  },
  methods: {
    formatDate(date) {
      const formattedDate = [];
      if (!date) return null;
      if (date[0]) {
        const [year, month, day] = date[0].split('-');
        formattedDate[0] = `${day}-${month}-${year}`;
      }
      if (date[1]) {
        const [year, month, day] = date[1].split('-');
        formattedDate[1] = `${day}-${month}-${year}`;
      }
      return formattedDate;
    },
    getAttempts() {
      const postData = {
        date: this.date,
      };
      axios.post(`/loginAttempts/getAll/${this.page}.json?token=${this.token}`, postData)
        .then((response) => {
          this.loginAttempts = response.data.loginAttempts;
          this.length = response.data.length;
        });
    },
  },
  mounted() {
    this.getAttempts();
  },
};
</script>
